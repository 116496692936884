<template>
  <v-chip :class="className" :style="css" :label="label" :color="colour" :small="small" :text-color="colour + 'Text'">
    <slot>
      <div class="font-weight-bold">{{ dropRate ? (text >= 0 ? text + '%' : 'N/A') : text }}</div>
    </slot>
  </v-chip>
</template>

<script>

import { hexOpacity } from '@/utils/shared/helpers'

export default {
  name: 'Chip',
  props: {
    text: { type: [String, Number], required: true },
    color: { type: String, default: '' },
    className: { type: String, default: '' },
    css: { type: String, default: '' },
    solid: { type: Boolean, default: true },
    label: { type: Boolean, default: false },
    campus: { type: Boolean, default: false },
    dropRate: { type: Boolean, default: false },
    enrolment: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  computed: {
    colour () {
      if (this.dropRate) {
        return this.dropRateColour(this.text)
      } else if (this.campus) {
        return this.text.toLowerCase()
      } else if (this.enrolment) {
        return this.enrolmentColour(this.text)
      } else {
        return this.solid ? [this.color, hexOpacity(this.getHexValue(this.color), 0.2)] : ['#FFFFFF', this.color]
      }
    }
  },
  methods: {
    getHexValue (colour) {
      if ((/#[\da-zfA-Z]{6}/).test(colour)) {
        return colour
      } else {
        return this.$vuetify.theme.currentTheme[colour]
      }
    },
    dropRateColour (dropRate) {
      if (dropRate > 30) return 'red'
      else if (dropRate > 20) return 'orange'
      else if (dropRate > 10) return 'blue'
      else if (dropRate >= 0) return 'green'
      else return 'grey'
    },
    enrolmentColour (enrolment) {
      const values = enrolment.split(' / ')
      return (parseInt(values[0]) >= parseInt(values[1])) ? 'red' : 'green'
    }
  }
}
</script>

<style scoped>

</style>
