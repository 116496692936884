<template>
  <div>
    <div v-if="icon">
      <p v-if="semesters.length < 1" :class="textClass">{{ noSemesterText }}</p>
      <v-chip v-for="(sem, idx) in semesters" :key="idx" :color="semesterCSS(sem[0])[0]" :small="small"
              class="text-body-2 font-weight-medium mr-2" :text-color="active ? semesterCSS(sem[0])[0] + 'Text' : 'secondary'">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" :small="small" :size="iconSize" :class="iconClass" :color="semesterCSS(sem[0])[0] + 'Text'">{{ semesterCSS(sem[0])[1] }}</v-icon>
          </template>
          <span>{{ sem }}</span>
        </v-tooltip>
      </v-chip>
    </div>
    <div v-else>
      <p v-if="semesters.length < 1" :class="textClass">{{ noSemesterText }}</p>
      <v-chip v-for="(sem, idx) in semesters" :key="idx" :color="semesterCSS(sem[0])[0]"
              class="'text-body-2 font-weight-medium mr-3" :text-color="semesterCSS(sem[0])[0] + 'Text'">
        <v-hover v-slot:default="{ hover }">
          <span>
            <v-icon v-if="!addCourse(sem.slice(0, 1))" :color="semesterCSS(sem[0])[0] + 'Text'" small class="mr-1">mdi-check-circle</v-icon>
            <v-icon v-else small :color="semesterCSS(sem[0])[0] + 'Text'" class="mr-1">
              {{ semesterCSS(sem[0])[1] }}
            </v-icon>
            {{ sem }}
             <v-fade-transition>
              <v-overlay v-if="hover && addCourse(sem.slice(0, 1))" absolute :color="semesterCSS(sem[0])[0]"
                         opacity="1" class="chip pointer" @click="viewTimeOptions(sem)">
                <span :class="'font-weight-medium ' + semesterCSS(sem[0])[0] + 'Text--text'">+ Add</span>
              </v-overlay>
               <v-overlay v-else-if="hover && !addCourse(sem.slice(0, 1))" absolute :color="semesterCSS(sem[0])[0]"
                          opacity="1" class="chip pointer" @click="removeTimetableEntry(sem)">
                <span :class="'font-weight-medium ' + semesterCSS(sem[0])[0] + 'Text--text'">
                  <v-icon small class="mr-1">mdi-minus-circle-outline</v-icon>Remove
                </span>
              </v-overlay>
            </v-fade-transition>
          </span>
        </v-hover>
      </v-chip>
    </div>
    <v-dialog v-model="timetableModal" :fullscreen="store.app.onMobile" max-width="1100px">
      <v-card :outlined="store.app.darkMode" class="px-4 py-2" style="border-radius: 5px">
        <v-card-text :class="store.app.onMobile ? 'pa-6 mb-12' : 'pa-1'">
          <CourseOfferings :course="course" :make-selection="select" :default-semester="semester" :reset="timetableModal"
                           modal show-stats enable-selection course-heading @update="handleUpdate($event)"/>
        </v-card-text>
        <v-card-actions :class="store.app.onMobile ? 'fixedBottom' : 'pt-0 pb-3'">
          <v-spacer/>
          <v-btn color="accent" class="px-4 mr-2" text @click="timetableModal = false">Cancel</v-btn>
          <v-btn color="accent" class="px-6" depressed @click="select = true">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CourseOfferings from '@/components/CourseOfferings'
import { useAllStores } from '@/stores/useAllStores'

export default {
  name: 'SemesterChips',
  components: { CourseOfferings },
  props: {
    course: { type: String, required: false },
    semesters: { type: Array, required: true },
    icon: { type: Boolean, default: false },
    iconSize: { type: Number, default: undefined },
    iconClass: { type: String, default: '' },
    small: { type: Boolean, default: true },
    textClass: { type: String, default: 'text--secondary mb-0' },
    noSemesterText: { type: String, default: 'No offerings' },
    active: { type: Boolean, default: true }
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    timetableModal: false,
    hover: false,
    semester: 0,
    select: false
  }),
  watch: {
    timetableModal: function (view) {
      if (view) this.$gtag.event('chip_view' + this.$route.path + '/' + this.course, { value: 1 })
    }
  },
  methods: {
    addCourse (semester) {
      return this.store.data.timetableCourses.findIndex((course) => {
        return course.data.courseResult.code === this.course && course.lecture.section[0] === semester
      }) < 0
    },
    handleUpdate (success) {
      if (success) this.timetableModal = false
      this.select = false
    },
    viewTimeOptions (semester) {
      if (this.course) {
        this.semester = semester.split(' ')[0] + ' 20' + semester.split(' ')[1]
        this.timetableModal = true
        this.$gtag.event('chip_add' + this.$route.path + '/' + this.course, { value: 1 })
      }
    },
    removeTimetableEntry (semester) {
      const cloned = [...(this.store.data.timetableCourses)]
      const entryIDX = this.store.data.timetableCourses.findIndex((course) => {
        return course.data.courseResult.code === this.course && course.lecture.section[0] === semester[0]
      })

      cloned.splice(entryIDX, 1)

      this.store.data.setTimetableCart(cloned)
      this.$gtag.event('chip_remove' + this.$route.path + '/' + this.course, { value: 1 })
    },
    semesterCSS (semester) {
      if (semester === 'F') {
        return [this.active ? 'orange' : 'border', 'mdi-leaf']
      } else if (semester === 'W') {
        return [this.active ? 'blue' : 'border', 'mdi-snowflake-variant']
      } else {
        return [this.active ? 'red' : 'border', 'mdi-flower-tulip']
      }
    }
  }
}
</script>

<style scoped>
  .chip {
    border-radius: 16px;
  }

</style>
